<template>
    <svg
        class="icon-instagram fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.024 129.989"
    >
        <path
            d="M102.419,36.689c-.872-2.24-1.912-3.84-3.592-5.52-1.679-1.68-3.276-2.72-5.516-3.59-1.692-.657-4.232-1.439-8.912-1.653-5.061-.23-6.58-.276-19.396-.276-1.602,0-3.028-.002-4.306,0v-.012c-8.946.01-10.662.07-15.09.27-4.68.215-7.221.995-8.913,1.655-2.24.872-3.84,1.912-5.52,3.592-1.68,1.68-2.722,3.28-3.592,5.52-.657,1.692-1.439,4.232-1.653,8.912-.23,5.06-.276,6.572-.276,19.39s.046,14.338.276,19.398c.213,4.68.996,7.22,1.653,8.91.872,2.241,1.912,3.837,3.592,5.517s3.28,2.72,5.52,3.59c1.693.657,4.233,1.439,8.913,1.655,5.06.23,6.579.28,19.396.28s14.336-.05,19.396-.28c4.68-.214,7.222-.994,8.912-1.654,2.241-.87,3.836-1.91,5.516-3.59,1.68-1.679,2.722-3.274,3.592-5.514.657-1.69,1.439-4.23,1.653-8.91.23-5.06.28-6.58.28-19.39s-.05-14.33-.28-19.39c-.213-4.68-.996-7.22-1.653-8.91ZM65.003,89.645c-13.613,0-24.65-11.032-24.65-24.645s11.037-24.65,24.65-24.65h0,.001,0c13.613,0,24.646,11.037,24.646,24.65s-11.033,24.645-24.646,24.645ZM90.625,45.138c-3.18,0-5.76-2.58-5.76-5.76s2.58-5.758,5.76-5.758v-.002c3.18,0,5.76,2.58,5.76,5.76s-2.58,5.76-5.76,5.76Z"
        />
        <circle cx="65.003" cy="65" r="16" />
        <path
            d="M129.896,28.172c-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624-4.301-4.108-9.561-6.608-15.829-7.512-3.037-.439-3.642-.569-19.182-.596h-21.16C37.891,0,29.953.028,28.41.156c-5.57.463-9.036,1.34-12.812,3.22-2.91,1.445-5.205,3.12-7.47,5.468C4.003,13.126,1.503,18.394.598,24.656.158,27.696.03,28.316.004,43.844-.006,49.02.004,55.832.004,64.969.004,92.089.034,100.019.164,101.559c.45,5.42,1.3,8.83,3.1,12.56,3.44,7.14,10.01,12.5,17.75,14.5,2.68.69,5.64,1.07,9.44,1.25,1.61.07,18.02.12,34.44.12s32.84-.02,34.41-.1c4.4-.207,6.955-.55,9.78-1.28,7.79-2.01,14.24-7.29,17.75-14.53,1.765-3.64,2.66-7.18,3.065-12.317.088-1.12.125-18.977.125-36.81s-.04-35.66-.128-36.78ZM112.708,84.788c-.24,5.108-1.05,8.597-2.23,11.65-1.23,3.156-2.87,5.834-5.54,8.502-2.667,2.67-5.341,4.314-8.497,5.54-3.054,1.187-6.546,1.996-11.656,2.23-5.12.233-6.751.29-19.788.29s-14.67-.057-19.79-.29c-5.109-.234-8.598-1.043-11.652-2.23-3.155-1.226-5.833-2.87-8.5-5.54-2.669-2.668-4.309-5.346-5.536-8.503-1.186-3.052-1.995-6.54-2.23-11.65-.232-5.12-.29-6.751-.29-19.787s.06-14.673.289-19.793c.23-5.108,1.04-8.597,2.23-11.65,1.23-3.156,2.87-5.834,5.54-8.502,2.668-2.669,5.346-4.309,8.503-5.535,3.052-1.187,6.54-1.996,11.65-2.23,5.119-.233,6.756-.29,19.785-.29h-.008.015-.008c13.033,0,14.664.057,19.782.29,5.11.234,8.6,1.043,11.66,2.23,3.16,1.225,5.83,2.865,8.5,5.535,2.67,2.668,4.31,5.346,5.54,8.503,1.18,3.052,1.99,6.54,2.23,11.65.23,5.12.29,6.756.29,19.792s-.06,14.668-.29,19.788Z"
        />
    </svg>
</template>
